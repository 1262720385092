
import DateFormatMixin from "@/mixins/DateFormatMixin.vue";
import FilesMixin from "@/mixins/FilesMixin.vue";
import { EventBus } from "@/plugins/eventBus";
import axios, { AxiosError } from "axios";
import Vue from "vue"
import { VueConstructor } from "vue/types/umd";
import DatePicker from "./DatePicker.vue";

  interface Document {
    id: number,
    recto_path: string,
    verso_path: string,
    type: string,
    name: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
    validity_start: string,
    validity_end: string,
    ocrRecto_id: number,
    ocrVerso_id: number,
    comment: string,
    card_number: string,
    verified: boolean,
    positions: string[],
    conditions: string[]
  }

  interface Body {
    rectoPath?: string,
    versoPath?: string | null,
    type?: string,
    validityStart?: string | null,
    validityEnd?: string | null,
    cardNumber?: string | null,
    comment?: string | null,
    name?: string | null,
    positions?: string[],
    conditions?: string[]
  }

  interface DocumentList{
    [key: string]: Document[]
  }

  interface Tag {
    id: number,
    tag: string
  }

  export default (Vue as VueConstructor<Vue & InstanceType<typeof FilesMixin>>).extend({
    name: 'DocumentCategory',
    components:{
      DatePicker
    },
    mixins: [
      FilesMixin,
      DateFormatMixin,
    ],
    props: {
      swId: {
        type: Number,
        required: true
      },
      categories: {
        type: Array as () => string[],
        required: true,
      },
      types: {
        type: Object as () => {[key: string]: string[]},
        required: true,
      },
      subject: {
        type: String,
        required: true,
      },
      documents: {
        type: Object as () => DocumentList,
        required: true,
      }
    },
    data:() => ({
      swSpaceId: null as null | number,
      modalAction: '',
      isModalOpen: false,
      rectoFile: null as null | File,
      versoFile: null as null | File,
      rectoButtonColor: 'default',
      versoButtonColor: 'default',
      rectoData: {
        originalName: '',
        type: '',
        returnedName: ''
      },
      versoData: {
        originalName: '',
        type: '',
        returnedName: ''
      },
      selectedType: '',
      selectedCategory: '',
      requiresVerso: false,
      validityDateMaxLimit: '',
      versoCategories: [
        'identity_documents',
        'driving_licence',
      ],
      categoriesMultipleTypes: [
        'identity_documents',
        'cv',
        'training_certificates'
      ],
      cardNumberTypes: [
        'identity',
        'passport',
        'residence_permit',
        'family_booklet',
        'residence_card',
        'foreign_identity',
        'social_security',
        'rib',
        'driving_licence',
        'caces',
        'habilitation',
        'fimo_fcos',
        'cdaph',
        'btp'
      ],
      categoryTypes: [] as Array<{
        text: string,
        value: string
      }>,
      subjectTypes: [] as Array<{
        text: string,
        value: string
      }>,
      documentId: 0,
      dateValidityStartMenu: false,
      dateValidityEndMenu: false,
      editDateValidityStartMenu: false,
      editDateValidityEndMenu: false,
      blobURL: '',
      startValidityDate: '',
      endValidityDate: '',
      cardNumber: '',
      comment: '',
      name: '',
      documentPositions: [] as Array<Tag>,
      documentConditions: [] as Array<Tag>,
      positionTags: [] as Array<Tag>,
      conditionTags: [] as Array<Tag>,
      currentDocument: {} as Document,
      updatedDocument: {} as Document,
      positionTagSearch: '',
      conditionTagSearch: '',
    }),
    watch: {
      isModalOpen() {
        // When the upload modal gets close, we reset all upload related data
        if (!this.isModalOpen) {
          let docData = {
            originalName: '',
            type: '',
            returnedName: ''
          }
          this.rectoData = docData
          this.versoData = docData
          this.rectoFile = null
          this.versoFile = null
          this.startValidityDate = ''
          this.endValidityDate = ''
          this.cardNumber = ''
          this.comment = ''
          this.name = ''
          this.selectedType = ''
          this.categoryTypes = []
          this.modalAction = ''
          this.updatedDocument = {} as Document
          this.currentDocument = {} as Document
          this.documentPositions = []
          this.documentConditions = []
          this.positionTags = []
          this.conditionTags = []
        }

        if ((this.selectedType === 'medical_examination' || this.currentDocument.type === 'medical_examination')) {
          this.getTags()
        }
      },
    },
    computed: {
      updatedRectoFileName() {
        return this.rectoData.originalName ? this.rectoData.originalName : this.currentDocument.name
      },
      updatedVersoFileName() {
        return this.versoData.originalName ? this.versoData.originalName + " verso" : this.currentDocument.name
      },
    },
    mounted() {
      this.validityDateMaxLimit = new Date(
        new Date().setFullYear(new Date().getFullYear() + 20)
      ).toISOString()
    },
    methods: {
      async getTags(document?: Document): Promise<void> {
        try {
          // Get agency ID if not already set
          if (!this.swSpaceId) {
            const response = await axios.get(`/v1/workers/${this.swId}?fields=agency_id`)
            this.swSpaceId = response.data.agency_id
          }

          // Fetch the space's tag lists
          const [positionResponse, conditionResponse] = await Promise.all([
            axios.get(`/v1/space/${this.swSpaceId}/tags?tag=position_tag`),
            axios.get(`/v1/space/${this.swSpaceId}/tags?tag=condition_tag`)
          ])

          this.positionTags = positionResponse.data
          this.conditionTags = conditionResponse.data

          // If document is provided, map its tags (for edit modal)
          if (document) {
            this.documentPositions = document.positions.map(tagName => {
              const existingTag = this.positionTags.find(t => t.tag === tagName)
              return existingTag || { id: Date.now() + Math.random(), tag: tagName }
            })

            this.documentConditions = document.conditions.map(tagName => {
              const existingTag = this.conditionTags.find(t => t.tag === tagName)
              return existingTag || { id: Date.now() + Math.random(), tag: tagName }
            })
          }
        } catch (error) {
          EventBus.$emit('snackbar', { axiosError: error })
        }
      },
      handleSelectFile(side: string) {
        const fileInputs = this.$refs[side] as Vue & {
          $refs: {
            input: HTMLElement
          }
        };
        fileInputs.$refs.input.click();
      },
      openUploadModal(category: string) {
        this.modalAction = 'upload'
        this.selectedCategory = category
        let categoryTypes = Object.keys(this.types).filter(key => this.types[key].includes(this.selectedCategory))
        for (const type of categoryTypes) {
          this.categoryTypes.push({
            text : this.$vuetify.lang.t('$vuetify.document_component.'+type),
            value: type
          })
        }
        this.initSubjectTypes()

        // If the category contains only 1 type, it is set as the selected type since no select will be shown in the template
        if (this.categoryTypes.length === 1){
          this.selectedType = this.categoryTypes[0].value
        }
        this.requiresVerso = this.versoCategories.includes(category)
        this.isModalOpen = true
      },
      openConfirmDeleteModal(documentId: number) {
        this.modalAction = 'delete'
        this.isModalOpen = true
        this.documentId = documentId
      },
      async openEditModal(document: Document) {
        this.currentDocument = document
        this.updatedDocument = { ...document }
        this.initSubjectTypes()

        // Initialize tags if it's a medical examination
        if (document.type === 'medical_examination') {
          await this.getTags(document)
        }

        this.modalAction = 'edit'
        this.isModalOpen = true
      },
      handleFileDrop(event: DragEvent, category: string, side = 'recto') {
        event.preventDefault()
        const files = event.dataTransfer?.files
        if (files && files[0]) {
          // Set the file to the right side of the document
          if(side === "recto") {
            this.rectoFile = files[0]
          } else {
            this.versoFile = files[0]
          }
          // If modal is already opened, we on't re-open it
          if (!this.isModalOpen) {
            this.openUploadModal(category)
          }
          this.uploadFile(files[0], side)
        }
      },
      validateUpload() {
        if (this.rectoFile && !this.requiresVerso) {
          this.rectoData.type = this.selectedType
          this.$emit("uploadFile", this.rectoData, null)
        }
        if (this.rectoFile && (this.requiresVerso && this.versoFile)) {
          this.rectoData.type = this.selectedType
          this.versoData.type = this.selectedType
          this.$emit("uploadFile", this.rectoData, this.versoData)
        }
        // display the create modal
        this.modalAction = 'create'
      },
      uploadFile(file: File, side: string) {
        if (file && file.size <= 8388608) {
          let formData = new FormData()

          formData.append("files[]", file)

          axios
            .post("/uploads", formData)
            .then((response) => {
              // Build the objects to send to the DocumentForm through WorkerFormDocument
              if (side === 'recto') {
                this.rectoData = {
                  originalName: file.name,
                  type: this.selectedType,
                  returnedName: response.data.success[0]
                }

                if (this.modalAction === 'edit') {
                  this.updatedDocument.recto_path = response.data.success[0]
                }
              } else {
                this.versoData = {
                  originalName: file.name,
                  type: this.selectedType,
                  returnedName: response.data.success[0]
                }
                if (this.modalAction === 'edit') {
                  this.updatedDocument.verso_path = response.data.success[0]
                }
              }
            })
            .catch((error: AxiosError) => {
              EventBus.$emit('snackbar', { axiosError: error })
          })
        } else {
          EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.subscription_page.non_compliant_file_error'), color: 'error'})
        }
      },
      deleteDocument(workerId: number, documentId: number) {
        axios
        .delete(`/v1/workers/${workerId}/documents/${documentId}`)
        .then(() => {
            // success snackbar
            EventBus.$emit('snackbar', { message: this.$vuetify.lang.t('$vuetify.document_deleted') })
            this.$emit("fileDeleted", true)
            this.isModalOpen = false
            this.documentId = 0
            this.currentDocument = {} as Document
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
        })
      },
      downloadDocument(rectoUrl: string, versoUrl = null as null|string) {
        this.launchFileDownload(rectoUrl)
        if (versoUrl){
          this.launchFileDownload(versoUrl)
        }
      },
      createDocument() {
        let body = {
          'rectoPath': this.rectoData.returnedName,
          'versoPath': this.versoData.returnedName,
          'type': this.selectedType,
          'validityStart': this.startValidityDate ? `${this.startValidityDate} 00:00:00` : null,
          'validityEnd': this.endValidityDate ?  `${this.endValidityDate} 00:00:00` : null,
          'cardNumber': this.cardNumber === '' ? null : this.cardNumber,
          'comment': this.comment === '' ? null : this.comment,
          'name': this.name === '' ? null: this.name,

        } as Body

        if (this.selectedType === 'medical_examination') {
          body.positions = this.documentPositions.map(tag => tag.tag)
          body.conditions = this.documentConditions.map(tag => tag.tag)
        }

        // Remove keys with no values
        let key: keyof Body
        for (key in body) {
          if (!body[key]) {
            delete body[key]
          }
        }

        axios
          .post(`/v1/workers/${this.swId}/documents`, body)
          .then(() => {
            // emit refetch of docs
            this.$emit('filePosted', true)
            EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.document_saved')});
            // clear out form data and hide it
            this.isModalOpen = false
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error});
        })
      },
      updateDocument() {
        // Build an object based on the updated properties of a document
        let body = {
          rectoPath: this.updatedDocument.recto_path,
          versoPath: this.updatedDocument.verso_path,
          type: this.updatedDocument.type,
          name: this.updatedDocument.name,
          validityStart: this.updatedDocument.validity_start ? `${this.updatedDocument.validity_start} 00:00:00` : null,
          validityEnd: this.updatedDocument.validity_end ? `${this.updatedDocument.validity_end} 00:00:00` : null,
          comment: this.updatedDocument.comment,
          cardNumber: this.updatedDocument.card_number
        } as Body


        // Need key and value of currentDocument
        let docKey: keyof Document
        let bodyKey: keyof Body
        // Remove keys that are equal to the currentDocument
        for (docKey in this.currentDocument) {
          // Translate snake case to camel case
          bodyKey = docKey.replace(/_([a-z])/g, g => g[1].toUpperCase()) as keyof Body

          // If a value wasn't updated, remove it from the body. Type is mandatory
          if ((this.updatedDocument[docKey] === this.currentDocument[docKey]) && docKey !== 'type') {
            delete body[bodyKey]
          }
        }

        // Add tags if it's a medical examination
        if (this.updatedDocument.type === 'medical_examination') {
          body.positions = this.documentPositions.map(tag => tag.tag)
          body.conditions = this.documentConditions.map(tag => tag.tag)
        }

        // call to the PUT route
        axios
          .put(`/v1/workers/${this.swId}/documents/${this.currentDocument.id}`, body)
          .then(() => {
            // emit refetch of docs
            this.$emit('filePosted', true)
            EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.document_saved')});
            // clear out form data and hide it
            this.isModalOpen = false
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error});
        })
      },
      initSubjectTypes() {
        let validTypes = Object.keys(this.types).filter(key => this.types[key].includes(this.subject))
        // Populates the v-select for type selection
        for (const type of validTypes) {
          this.subjectTypes.push({
            text : this.$vuetify.lang.t('$vuetify.document_component.'+type),
            value: type
          })
        }
      },
      getEndDateError(endDate: string, startDate: string) {
        if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
          return this.$vuetify.lang.t('$vuetify.ending_date_more_than_starting_date')
        }
        return ''
      },
      getTagSearchValue(tagType: string): string {
        return tagType === 'position' ? this.positionTagSearch : this.conditionTagSearch;
      },
      findExistingTag(tagType: string, input: string): Tag | undefined {
        const tagList = tagType === 'position' ? this.positionTags : this.conditionTags;
        return tagList.find(t => t.tag.toLowerCase() === input.toLowerCase());
      },
      addTagToDocument(tagType: string, tag: Tag): void {
        const tagList = tagType === 'position' ? this.documentPositions : this.documentConditions;
        const isTagPresent = tagList.some(t => t.id === tag.id);

        if (!isTagPresent) {
          tagList.push(tag);
        }
      },
      createAndAddNewTag(tagType: string, input: string): void {
        const newTag: Tag = {
          id: Date.now() + Math.random(),
          tag: input
        };

        if (tagType === 'position') {
          this.documentPositions.push(newTag);
          this.positionTags.push(newTag);
        } else {
          this.documentConditions.push(newTag);
          this.conditionTags.push(newTag);
        }
      },
      clearSearchInput(tagType: string): void {
        if (tagType === 'position') {
          this.positionTagSearch = '';
        } else {
          this.conditionTagSearch = '';
        }
      },
      createNewTag(event: KeyboardEvent, tagType: string): void {
        const input = this.getTagSearchValue(tagType);
        if (!input) return;

        const existingTag = this.findExistingTag(tagType, input);

        if (existingTag) {
          this.addTagToDocument(tagType, existingTag);
        } else {
          this.createAndAddNewTag(tagType, input);
        }

        this.clearSearchInput(tagType);
      },
    }
  })
