
  import Vue, { VueConstructor } from "vue"
  import axios, { AxiosError, AxiosResponse } from "axios"
  import { EventBus } from "@/plugins/eventBus"
  import FilesMixin from "@/mixins/FilesMixin.vue"
  import DocumentCategory from "../DocumentCategory.vue"

  interface Document {
    id: number,
    recto_path: string,
    verso_path: string,
    type: string,
    name: string,
    created_at: string,
    updated_at: string,
    deleted_at: string,
    validity_start: string,
    validity_end: string,
    ocrRecto_id: number,
    ocrVerso_id: number,
    comment: string,
    card_number: string,
    verified: boolean,
    positions: string[],
    conditions: string[],
  }

  interface DocumentList {
    worker: {
      [key: string]: Document[]
    },
    employer: {
      [key: string]: Document[]
    },
  }

  interface DocumentTypeList {
    [key: string]: string[]
  }

  export default (Vue as VueConstructor<Vue & InstanceType<typeof FilesMixin>>).extend({
    name: 'WorkerFormDocument',
    mixins: [
      FilesMixin,
    ],
    components: {
      DocumentCategory
    },
    props: {
      id: {
        type: Number,
        required: true,
        default: 1
      },
    },
    data: () => ({
      tab: 0,
      docList: {
        worker: {
        },
        employer: {
        }
      } as DocumentList,
      docTypeList: {
        identity: ['worker', 'identity_documents'],
        passport: ['worker', 'identity_documents'],
        residence_permit: ['worker', 'identity_documents'],
        family_booklet: ['worker', 'identity_documents'],
        residence_card: ['worker', 'identity_documents'],
        foreign_identity: ['worker', 'identity_documents'],
        cv: ['worker', 'cv'],
        active_proposal: ['worker', 'cv'],
        profile: ['worker', 'photo'],
        social_security: ['worker', 'carte_vital_or_certificate'],
        rib: ['worker', 'rib'],
        proof_of_address: ['worker', 'proof_of_address'],
        medical_examination: ['worker', 'visit_medical_aptitude'],
        work_certificate: ['worker', 'work_certificate'],
        driving_licence: ['worker', 'driving_licence'],
        caces: ['worker', 'training_certificates'],
        habilitation: ['worker', 'training_certificates'],
        diploma: ['worker', 'training_certificates'],
        fimo_fcos: ['worker', 'training_certificates'],
        other_certificate: ['worker', 'training_certificates'],
        cdaph: ['worker', 'disability_situation'],
        btp: ['worker', 'btp_card'],
        vaccination_booklet: ['worker', 'vaccination'],
        other: ['worker', 'other_documents'],
        contract: ['employer', 'contract'],
        internal_document: ['employer', 'internal_document'],
        payslip: ['employer', 'payslip'],
        timesheet: ['employer', 'timesheet'],
        employer_attestation: ['employer', 'employer_attestation'],
      } as DocumentTypeList,
      fileType: ''
    }),
    watch: {
      id: {
        handler() {
          this.refreshComponent()
        },
      }
    },
    mounted() {
      this.getDocuments()
    },
    methods: {
      getDocuments() {
        // Reset the list before refetching from the api
        this.docList = {
          worker: {
            identity_documents: [],
            cv: [],
            photo: [],
            carte_vital_or_certificate: [],
            rib: [],
            proof_of_address: [],
            visit_medical_aptitude: [],
            work_certificate: [],
            driving_licence: [],
            training_certificates: [],
            disability_situation: [],
            btp_card: [],
            vaccination: [],
            other_documents: []
          },
          employer: {
            contract : [],
            internal_document: [],
            payslip: [],
            timesheet: [],
            employer_attestation: []
          }
        } as DocumentList
        axios
          .get(`/v1/workers/${this.id}/documents`)
          .then((response: AxiosResponse) => {
            const data = response.data
            // loop on the returned doc list
            for (const document of data) {
              // Sort the document on subject and category
              if (document.type as keyof DocumentTypeList in this.docTypeList) {
                let subject = this.docTypeList[document.type][0] as keyof DocumentList
                let category = this.docTypeList[document.type][1] as keyof DocumentList
                this.$set(this.docList[subject], category, [...this.docList[subject][category], document])
              }
            }
          })
          .catch((error: AxiosError) => {
            EventBus.$emit('snackbar',{axiosError: error})
          })
        },
        refreshComponent() {
          this.fileType= ""
          this.getDocuments()
        }
    }
  })
